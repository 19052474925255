<template>
  <div class="alarmSetting-new">
    <el-row :gutter="40">
      <el-col :span="12">
        <el-table
          :data="tableData"
          style="width: 100%;"
          height="40vh"
          @row-click="RowFun"
          highlight-current-row
        >
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="alarmType" label="报警类型" width="180" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="alarmName">
                {{ typeObj[scope.row.alarmType] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="level" label="报警等级">
            <template slot-scope="scope">{{ alarmLevelObj[Number(scope.row.alarmLevel)] }}</template>
          </el-table-column>
          <el-table-column prop="cph" label="车牌号" width="110"></el-table-column>
          <el-table-column prop="cph" label="报警来源">
            <template slot-scope="scope">{{ alarmSourceObj[Number(scope.row.alarmSource)] }}</template>
          </el-table-column>
          <!-- <el-table-column prop="alarmSignTime" label="报警发生时间" width="180"></el-table-column> -->
          <el-table-column prop="time" label="报警时间" width="180">
            <template slot-scope="scope">
            {{ scope.row | filterAlarmTime(scope.row.time) }}
        </template>
          </el-table-column>
          <!-- <el-table-column prop="alarmEndTime" label="报警结束时间" width="180"></el-table-column> -->
        </el-table>
      </el-col>
      <el-col :span="12">
        <span>报警详情</span>
        <el-button
          type="primary"
          size="small"
          style="margin-left:16px;"
          :disabled="!detailForm.alarmBaseVO.cph"
          @click="alarmDealFun"
        >报警处理</el-button>
        <div class="information">
          <div class="head">
            <p>
              <span>车牌号：</span>
              <span>{{ detailForm.alarmBaseVO.cph }}</span>
            </p>
            <p>
              <span>所属车队：</span>
              <span>{{ detailForm.alarmBaseVO.companyName }}</span>
            </p>
            <p>
              <span>驾驶员：</span>
              <span>{{ detailForm.alarmBaseVO.driverName }}</span>
            </p>
            <p class="noWrap">
              <span>报警类型：</span>
              <span class="sign">
                {{ detailForm.alarmBaseVO.alarmName
                }}
              </span>
            </p>
            <p>
              <span>报警等级：</span>
              <span class="sign">
                {{
                alarmLevelObj[Number(detailForm.alarmBaseVO.alarmLevel)]
                }}
              </span>
            </p>
            <p class="noWrap">
              <span>驾驶证号：</span>
              <span>{{ detailForm.alarmBaseVO.drivingLicense }}</span>
            </p>
          </div>
          <p class="split-line"></p>
          <div class="body">
            <el-table
              :data="detailForm.alarmDetailVoList"
              height="calc(40vh - 118px)"
              class="no-blank alarm-table"
              ref="tableName"
              @row-click="RowDetailFun"
            >
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column prop="alarmSignTime" label="报警时间"></el-table-column>
              <el-table-column prop="velocity" label="速度/限速（km/h)" width="160">
                <template slot-scope="scope">
                  <span>{{scope.row.velocity?scope.row.velocity:'-'}}/{{detailForm.alarmBaseVO.limitValue?detailForm.alarmBaseVO.limitValue:'-'}}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="detailForm.alarmBaseVO.alarmType === 2012||detailForm.alarmBaseVO.alarmType === 2051"
                label="超速比例"
                width="80"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.velocity | filterProportion(detailForm.alarmBaseVO.limitValue)}}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="vehStatus"
                label="车辆状态"
                width="180"
                :show-overflow-tooltip="true"
              ></el-table-column>
              <el-table-column
                    prop="alarmContent"
                    label="报警内容"
                    v-if="detailForm.alarmBaseVO.alarmType === 2051||detailForm.alarmBaseVO.alarmType === 2052 || detailForm.alarmBaseVO.alarmType === 2054 ||detailForm.alarmBaseVO.alarmType === 2053"
                    width="180"
                    :show-overflow-tooltip="true"
                  ></el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="40">
      <el-col :span="12">
        <p class="title">
          报警附件
          <el-button
            type="primary"
            size="small"
            style="margin-left:16px;"
            v-if="fileForm.length>0"
            @click="downLoadFile"
          >下载附件</el-button>
          <!-- 二级 终端产生的报警才有附件 -->
          <el-button
            type="primary"
            size="small"
            style="margin-left:16px;"
            v-if="fileForm.length<1&&alarmDetail&&alarmDetail.alarmLevel==2&&alarmDetail.alarmSource==2  && locatePointShow"
            @click="orderAttachment"
          >获取附件</el-button>
        </p>
        <div v-if="fileForm.length<1" class="null-tips">暂无附件</div>
        <div v-else style="height: calc(60vh - 144px);">
          <!-- 视频 -->
          <div class="mediaStyle" v-if="videoPlayArr.length>0">
          <el-carousel :interval="10000" indicator-position="none" trigger="click">
           <el-carousel-item v-for="item in videoPlayArr" :key="item.time">
             <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                :options="item.fileNo"
              ></video-player>
           </el-carousel-item> 
          </el-carousel>
          </div>
          <!-- 图片 -->
          <div v-for="item in imgShowArr" :key="item.time" class="mediaStyle">
            <img :src="item.alarmFilePath" alt />
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <p class="title">轨迹</p>
        <div class="map-style">
          <searchMap ref="searchMap" :locatePointShow="locatePointShow" />
        </div>
      </el-col>
    </el-row>
    <!-- 报警处理弹窗 -->
    <el-dialog
      title="报警详情"
      :visible.sync="dialogVisible"
      width="50%"
      top="10vh"
      custom-class="alarm-detail"
    >
      <div>
        <alarm-dia
          class="body"
          :alarmID="alarmID"
          :alarmDetail="alarmDetail"
          @cancelDialog="cancelDialog"
          @fileOpenDialog="fileOpenDialog"
          :showRecord="false"
          ref="alarmDia"
          v-if="dialogVisible"
        ></alarm-dia>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false" v-if="!fileDialog">取消</el-button>

        <el-button type="primary" size="small" @click="onSaveDetail">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import alarmEdit from './alarmEdit/alarmEdit.vue'
import search from './search/search.vue'
import table from './table/table.vue'
import pagination from '@/components/pagination/pagination.vue'
import alarmDia from './components/alarmDia.vue'
import searchMap from './components/map.vue'
import { formatDict,formatDate } from '@/common/utils/index'
import {
  getAlarmConfigs,
  queryDictsByCodes,
  queryNoDisposeAlarm,
  queryAlarmDetail,
  queryAlarmFile,
  alarmTraceList
} from '@/api/lib/api.js'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
export default {
  components: {
    'my-search': search,
    'my-table': table,
    'my-page': pagination,
    'alarm-dia': alarmDia,
    alarmEdit,
    searchMap,
    'video-player': videoPlayer
  },
  data () {
    return {
      fileDialog: false,
      tableData: [],
      detailForm: {
        alarmBaseVO: {
          cph: '',
          companyName: '',
          driverName: '',
          drivingLicense: '',
          alarmName: '',
          alarmLevel: ''
        },
        alarmDetailVoList: [],
        disposeAlarmDataVoList: []
      },
      system: sessionStorage.getItem('system').toString(),
      alarmLevelObj: {},
      typeObj: null,
      alarmSourceObj: null,
      dialogVisible: false,
      alarmID: null,
      alarmDetail: null,
      fileForm: [],
      moniData: {},
      globalAlarm: null,
      timer: null,
      locatePointShow: null,//报警点地图定位点
      videoPlayArr:[],
      imgShowArr:[]
    }
  },
  filters: {
    filterProportion (item, limitValue) {
      let str = "-";
      if(limitValue && limitValue !==0) {
        const poportion = (item - limitValue) / limitValue;
        str = `${Math.round(poportion * 100)}%`
      }
      return str
    },
    filterAlarmTime(item,time){
      let date = "-";
      if(time){
       date = formatDate(time);
      }
      return date
    }
  },
  methods: {
    RowDetailFun (row) {
      this.locatePointShow = row;//地图轨迹标点
      const requireData = {
        fileNo: row.fileNo,
        terminalPhoneNo: this.detailForm.alarmBaseVO.terminalPhoneNo,
        alarmSignTime: row.alarmSignTime,
        alarmEndTime: row.alarmEndTime
      }
      this.fileForm = [];
      this.videoPlayArr = [];
      this.imgShowArr = [];
      if (!row.fileNo) {
        return
      }
      //下发指令 ["报警号", "设备号", "报警时间", "报警序号", "附件数"],
      queryAlarmFile(requireData).then((res) => {
        if (res.code === 1000) {
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              if (item.alarmFileType === 0) {
                this.fileForm.push(item)
                this.imgShowArr.push(item)
              } else if (item.alarmFileType === 2) {
                let obj = {
                  playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                  autoplay: true, //如果true,浏览器准备好时开始回放。
                  muted: false, // 默认情况下将会消除任何音频。
                  loop: false, // 导致视频一结束就重新开始。
                  preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                  language: 'zh-CN',
                  aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                  fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                  sources: [
                    {
                      src: item.alarmFilePath, // 路径
                      // src:'http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8',
                      type: 'video/mp4' // 类型
                      // type:'application/x-mpegURL'
                    }
                  ],
                  poster:
                    'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg', //你的封面地址
                  notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                  controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true //全屏按钮
                  }
                }
                item.fileNo = obj
                this.fileForm.unshift(item);//视频推到数组最前面
                this.videoPlayArr.push(item);//轮播播放视频
              }
            })
          }
        }
      })
    },
    //指令获取终端附件
    orderAttachment(){
      const { alarmNo,alarmSignTerminalId,alarmSignTime,alarmSignFileNum,alarmSignNum } = this.locatePointShow;
      let timeTlag = false
      try {
        let currentTime = new Date().getTime()
        let sendAlarmSignTime = new Date(alarmSignTime).getTime();
        timeTlag = Math.floor((currentTime - sendAlarmSignTime)/1000/60)>5
      } catch (error) {}
      //产生报警超过5分钟，若无附件 则下发指令
      if(alarmNo&&alarmSignTerminalId&&alarmSignTime&&alarmSignFileNum&&timeTlag){
        //下发指令 ["报警号alarmNo", "设备号alarmSignTerminalId", "报警时间alarmSignTime", "报警序号alarmSignNum", "附件数alarmSignFileNum"],
          this.$yhsdp.attachmentRequest(this.alarmDetail.terminalPhoneNo, alarmNo, alarmSignTerminalId, alarmSignTime, alarmSignNum,alarmSignFileNum)
          this.$message.warning('已下发指令获取，请稍后查看！')
      }else{
        this.$message.warning('暂无附件，已下发指令获取！')
      }
    },
    //下载附件
    downLoadFile () {
      for (let i = 0; i < this.fileForm.length; i++) {
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = this.fileForm[i].alarmFilePath;
        document.body.appendChild(iframe);
        setTimeout(() => {
          iframe.remove();
        }, 1000);
      }
    },
    // 关闭dialog
    cancelDialog (res) {
      this.dialogVisible = false
      do {
        if (!res) break
        if (res.length === 1) {
          this.$message.success(res[0])
          break
        }
        res.forEach((item, index) => {
          if (index === 0) {
            this.$message.warning(item)
          } else {
            setTimeout(() => {
              this.$message.error(item)
            }, 200 * index)
          }
        })
      } while (false)
      this.getAlarmList()
    },
    // 报警弹窗
    fileOpenDialog (val) {
      this.fileDialog = val
    },
    //报警处理
    onSaveDetail () {
      this.$refs.alarmDia.save()
    },
    RowFun (row) {
      this.getDetail(row);
      this.getAlarmTrace(row)
    },
    //获取近一个小时未处理的报警数据
    getAlarmList () {
      if (this.timer) {
        clearInterval(this.timer)
      }
      const intervalIimerFn = () => {
        queryNoDisposeAlarm({ system: this.system }).then(res => {
          if (res.code === 1000) {
            this.tableData = res.data;
            if (res.data.length > 0) {
              if (this.globalAlarm) {
                //全局消息列表跳转过来的
                this.getDetail(this.globalAlarm);//详情 
                this.getAlarmTrace(this.globalAlarm);//请求轨迹
              } else {
                this.getDetail(res.data[0]);//详情 
                this.getAlarmTrace(res.data[0]);//请求轨迹
              }
            }
          }
        }).catch(err => {
          console.log(err)
        })
      }
      intervalIimerFn();
      this.timer = setInterval(() => {
        intervalIimerFn()
      }, 300000);
    },
    //获取报警前后几分钟的轨迹
    getAlarmTrace (row) {
      const data = {
        alarmNo: row.alarmNo,
        // alarmSignTime: row.alarmSignTime,
        // alarmEndTime: row.alarmEndTime,
        alarmSignTime: row.time,
        alarmEndTime: row.time,
        minute: 5
      }
      alarmTraceList(data).then(res => {
        this.$refs.searchMap.onTrailPlay(res)
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'BJDJ,D0008,D0009,D0010,BJLY' }).then((res) => {
        if (res.code === 1000) {
          this.alarmLevelObj = formatDict(res.data.BJDJ)
          this.alarmSourceObj = formatDict(res.data.BJLY)
          this.typeObj = formatDict(
            [].concat(res.data.D0008, res.data.D0009, res.data.D0010)
          )
        }
      })
    },
    alarmDealFun () {
      this.dialogVisible = true;
      clearInterval(this.timer);//关闭计时器
    },
    //获取详情
    getDetail (row) {
      let data = {
        alarmNo: row.alarmNo,
        system: this.system,
        terminalPhoneNo: row.terminalPhoneNo,
        alarmSignTime: row.alarmSignTime,
        alarmEndTime: row.alarmEndTime,
        idStr:row.idStr
      }
      //报警处理数据
      this.alarmID = [row.alarmNo]
      this.alarmDetail = row
      this.fileDialog = false
      //报警处理数据
      queryAlarmDetail(data).then((res) => {
        if (res.code === 1000) {
          this.detailForm = res.data
          if (res.data.alarmDetailVoList.length > 0) {
            this.RowDetailFun(res.data.alarmDetailVoList[0]);//获取附件
          }
          this.$nextTick(() => {
            this.$refs.tableName.doLayout();
          })
        } else {
          this.$message.warning('暂无查询数据！')
          this.detailForm = {
            alarmBaseVO: {
              cph: '',
              companyName: '',
              driverName: '',
              drivingLicense: '',
              alarmName: '',
              alarmLevel: ''
            },
            alarmDetailVoList: [],
            disposeAlarmDataVoList: []
          }
        }
      })
    },
  },
  created () {
    this.getDicts();
    this.getAlarmList();
    if (this.$route.query.alarmNo) {
      this.globalAlarm = this.$route.query;//车辆报警消息点击跳转过来的携带数据
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style lang='scss' scoped>
.alarmSetting-new {
  height: 100%;
  & > .alarm-tab {
    height: 100%;
  }
  /deep/.el-table.alarm-table .el-table__empty-block .el-table__empty-text {
    height: calc(40vh - 200px);
  }
  .noWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .null-tips {
    width: 100%;
    line-height: 22vh;
    text-align: center;
    color: #999;
  }
  .mediaStyle {
    width: 45%;
    height: 50%;
    display: inline-block;
    overflow: hidden;
    margin-right: 2%;
    img {
      width: 100%;
      height: 100%;
    }
    .video-player {
      width: 100%;
      height: 100%;
    }
    /deep/.el-carousel{
      height: 100%;
      .el-carousel__container{
        height: 100%;
      }
    }
  }
  .title {
    margin-bottom: 6px;
  }
  .map-style {
    width: 100%;
    height: calc(60vh - 136px);
  }
  @include themify() {
    .alarmName {
      color: themed('b4');
      cursor: pointer;
    }
    .information {
      padding: 12px 0;
      margin-bottom: 6px;
      .head {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: themed('n8');
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
        // p:nth-of-type(4n) {
        //   text-align: right;
        // }
      }
      .split-line {
        border-bottom: 1px solid;
        border-bottom-color: themed('n4');
        margin: 0 -12px 12px;
      }
    }
  }
}
</style>
