var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarmSetting-new" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    height: "40vh",
                    "highlight-current-row": ""
                  },
                  on: { "row-click": _vm.RowFun }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "alarmType",
                      label: "报警类型",
                      width: "180",
                      "show-overflow-tooltip": true
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", { staticClass: "alarmName" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.typeObj[scope.row.alarmType]) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "level", label: "报警等级" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.alarmLevelObj[Number(scope.row.alarmLevel)]
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cph", label: "车牌号", width: "110" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "cph", label: "报警来源" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.alarmSourceObj[
                                  Number(scope.row.alarmSource)
                                ]
                              )
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "time", label: "报警时间", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("filterAlarmTime")(
                                    scope.row,
                                    scope.row.time
                                  )
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("span", [_vm._v("报警详情")]),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "16px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: !_vm.detailForm.alarmBaseVO.cph
                  },
                  on: { click: _vm.alarmDealFun }
                },
                [_vm._v("报警处理")]
              ),
              _c("div", { staticClass: "information" }, [
                _c("div", { staticClass: "head" }, [
                  _c("p", [
                    _c("span", [_vm._v("车牌号：")]),
                    _c("span", [_vm._v(_vm._s(_vm.detailForm.alarmBaseVO.cph))])
                  ]),
                  _c("p", [
                    _c("span", [_vm._v("所属车队：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.companyName))
                    ])
                  ]),
                  _c("p", [
                    _c("span", [_vm._v("驾驶员：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.driverName))
                    ])
                  ]),
                  _c("p", { staticClass: "noWrap" }, [
                    _c("span", [_vm._v("报警类型：")]),
                    _c("span", { staticClass: "sign" }, [
                      _vm._v(
                        " " + _vm._s(_vm.detailForm.alarmBaseVO.alarmName) + " "
                      )
                    ])
                  ]),
                  _c("p", [
                    _c("span", [_vm._v("报警等级：")]),
                    _c("span", { staticClass: "sign" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.alarmLevelObj[
                              Number(_vm.detailForm.alarmBaseVO.alarmLevel)
                            ]
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("p", { staticClass: "noWrap" }, [
                    _c("span", [_vm._v("驾驶证号：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.detailForm.alarmBaseVO.drivingLicense))
                    ])
                  ])
                ]),
                _c("p", { staticClass: "split-line" }),
                _c(
                  "div",
                  { staticClass: "body" },
                  [
                    _c(
                      "el-table",
                      {
                        ref: "tableName",
                        staticClass: "no-blank alarm-table",
                        attrs: {
                          data: _vm.detailForm.alarmDetailVoList,
                          height: "calc(40vh - 118px)"
                        },
                        on: { "row-click": _vm.RowDetailFun }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", label: "序号" }
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "alarmSignTime", label: "报警时间" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "velocity",
                            label: "速度/限速（km/h)",
                            width: "160"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.velocity
                                          ? scope.row.velocity
                                          : "-"
                                      ) +
                                        "/" +
                                        _vm._s(
                                          _vm.detailForm.alarmBaseVO.limitValue
                                            ? _vm.detailForm.alarmBaseVO
                                                .limitValue
                                            : "-"
                                        )
                                    )
                                  ])
                                ]
                              }
                            }
                          ])
                        }),
                        _vm.detailForm.alarmBaseVO.alarmType === 2012 ||
                        _vm.detailForm.alarmBaseVO.alarmType === 2051
                          ? _c("el-table-column", {
                              attrs: { label: "超速比例", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("filterProportion")(
                                                scope.row.velocity,
                                                _vm.detailForm.alarmBaseVO
                                                  .limitValue
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2756181470
                              )
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            prop: "vehStatus",
                            label: "车辆状态",
                            width: "180",
                            "show-overflow-tooltip": true
                          }
                        }),
                        _vm.detailForm.alarmBaseVO.alarmType === 2051 ||
                        _vm.detailForm.alarmBaseVO.alarmType === 2052 ||
                        _vm.detailForm.alarmBaseVO.alarmType === 2054 ||
                        _vm.detailForm.alarmBaseVO.alarmType === 2053
                          ? _c("el-table-column", {
                              attrs: {
                                prop: "alarmContent",
                                label: "报警内容",
                                width: "180",
                                "show-overflow-tooltip": true
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "p",
              { staticClass: "title" },
              [
                _vm._v(" 报警附件 "),
                _vm.fileForm.length > 0
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "16px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.downLoadFile }
                      },
                      [_vm._v("下载附件")]
                    )
                  : _vm._e(),
                _vm.fileForm.length < 1 &&
                _vm.alarmDetail &&
                _vm.alarmDetail.alarmLevel == 2 &&
                _vm.alarmDetail.alarmSource == 2 &&
                _vm.locatePointShow
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "16px" },
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.orderAttachment }
                      },
                      [_vm._v("获取附件")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.fileForm.length < 1
              ? _c("div", { staticClass: "null-tips" }, [_vm._v("暂无附件")])
              : _c(
                  "div",
                  { staticStyle: { height: "calc(60vh - 144px)" } },
                  [
                    _vm.videoPlayArr.length > 0
                      ? _c(
                          "div",
                          { staticClass: "mediaStyle" },
                          [
                            _c(
                              "el-carousel",
                              {
                                attrs: {
                                  interval: 10000,
                                  "indicator-position": "none",
                                  trigger: "click"
                                }
                              },
                              _vm._l(_vm.videoPlayArr, function(item) {
                                return _c(
                                  "el-carousel-item",
                                  { key: item.time },
                                  [
                                    _c("video-player", {
                                      ref: "videoPlayer",
                                      refInFor: true,
                                      staticClass:
                                        "video-player vjs-custom-skin",
                                      attrs: {
                                        playsinline: true,
                                        options: item.fileNo
                                      }
                                    })
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.imgShowArr, function(item) {
                      return _c(
                        "div",
                        { key: item.time, staticClass: "mediaStyle" },
                        [
                          _c("img", {
                            attrs: { src: item.alarmFilePath, alt: "" }
                          })
                        ]
                      )
                    })
                  ],
                  2
                )
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("p", { staticClass: "title" }, [_vm._v("轨迹")]),
            _c(
              "div",
              { staticClass: "map-style" },
              [
                _c("searchMap", {
                  ref: "searchMap",
                  attrs: { locatePointShow: _vm.locatePointShow }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报警详情",
            visible: _vm.dialogVisible,
            width: "50%",
            top: "10vh",
            "custom-class": "alarm-detail"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _vm.dialogVisible
                ? _c("alarm-dia", {
                    ref: "alarmDia",
                    staticClass: "body",
                    attrs: {
                      alarmID: _vm.alarmID,
                      alarmDetail: _vm.alarmDetail,
                      showRecord: false
                    },
                    on: {
                      cancelDialog: _vm.cancelDialog,
                      fileOpenDialog: _vm.fileOpenDialog
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              !_vm.fileDialog
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSaveDetail }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }