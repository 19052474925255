<template>
  <div class="alarm-dia">
    <div v-if="!fileDialog">     
      <div class="fixed" v-if="alarmDetail && alarmDetail.disposeStatus !== 1">
        <p class="title">报警处理</p>
        <div class="deal">
          <el-form
            :inline="true"
            :model="form"
            label-width="110px"
            label-position="left"
            class="demo-form deal-form"
            :rules="rules"
            ref="alarmForm"
          >
            <el-form-item label="报警判定：" prop="alarmConfirm" style="width:100%;">             
              <!-- <el-select
                v-model="form.alarmConfirm"
                placeholder="请选择"
                @change="changeConfirm"
              >
                <el-option
                  v-for="item in alarmConfirmList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select> -->
              <el-input disabled v-model="name1"></el-input>
            </el-form-item>
            <el-form-item label="处理方式：" prop="disposeWay" v-if="form.alarmConfirm==='1'">             
              <el-select
                v-model="form.disposeWay"
                placeholder="请选择"
                @change="changeWay"
              >
                <el-option
                  v-for="item in disposeWayList"
                  :label="item.dictValue"
                  :value="item.dictCode"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="文本模板"
              prop="textTemplate"
              v-if="form.disposeWay === '1' && form.alarmConfirm==='1'"
            >
              <el-select
                v-model="form.textTemplate"
                placeholder="请选择"
                @change="selectTextTemplate"
              >
                <el-option
                  v-for="(item, key) in templateList"
                  :key="key"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="电话号码："
              prop="phone"
              v-if="form.disposeWay === '3'"
            >
              <el-input
                v-model.trim="form.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item></el-form-item>
            <el-form-item label="处理描述：" prop="disposeDescription">
              <el-input
                type="textarea"
                v-model.trim="form.disposeDescription"
                placeholder="请输入***"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  handleDisposeAlarm,
  queryAlarmDetail,
  queryAlarmFile,
  queryMessageTemplateList,
  queryDictsByCodes
} from '@/api/lib/api.js'
import { checkPhone, formatDict } from '@/common/utils/index.js'

import { getCurent } from '@/common/utils/index'
export default {
  name: 'alarmDia',
  props: ['alarmID', 'alarmDetail', 'isGlobalAlarm'],
  data () {
    return {
      system: "0", //对应系统
      fileDialog: false,
      alarmLevelObj: {},
      disposeTypeObj: {},
      disposeWayObj: {},
      disposeWayList: [],
      alarmConfirmList:[],
      templateList: [],
      // detailForm: {
      //   alarmBaseVO: {
      //     cph: '',
      //     companyName: '',
      //     driverName: '',
      //     drivingLicense: '',
      //     alarmName: '',
      //     alarmLevel: ''
      //   },
      //   alarmDetailVoList: [],
      //   disposeAlarmDataVoList: []
      // },
      rules: {
        disposeWay: {
          required: true,
          message: '请选择处理方式',
          trigger: 'change'
        },
        textTemplate: {
          required: true,
          message: '请选择文本模板',
          trigger: 'change'
        },
        alarmConfirm: [
          { required: true, message: '请选择报警判定', trigger: 'blur' }
        ],
        // disposeDescription: [
        //   { required: true, message: '请输入处理描述', trigger: 'blur' }
        // ],
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ]
      },
      form: {
        disposeWay: '1',
        terminalPhoneNo: '',
        textTemplate: '自定义',
        disposeDescription: '',
        phone: '',
        alarmConfirm:'1',
      },
      name1:"确认报警",
    }
  },
  components: {
  },
  filters:{
    filterProportion(item,limitValue){
      let str = "-";
      if(limitValue && limitValue !==0) {
        const poportion = (item - limitValue) / limitValue;
        str = `${Math.round(poportion * 100)}%`
      }
      return str
    }
  },
  methods: {
    
    // 选择文本模板
    selectTextTemplate (id) {
      if (id !== '自定义' && id) {
        let element = this.templateList.find((item) => item.id === id)
        if (element) this.form.disposeDescription = element.detail
      } else {
        this.form.disposeDescription = ''
      }
    },
    changeConfirm(){
      this.$refs.alarmForm.clearValidate()
    },
    // 修改处理方式
    changeWay () {
      this.form.textTemplate = '自定义';
      this.form.disposeDescription = '';
    },
    // 提交
    save () {
      console.log("--------",this.alarmDetail);
      if (this.fileDialog) {
        this.fileDialog = !this.fileDialog
        this.$emit('fileOpenDialog', this.fileDialog)
        return
      }
      if (this.alarmDetail.disposeStatus === 1) {
        this.$emit('cancelDialog')
      } else {
        this.$refs.alarmForm.validate((valid) => {
          if (valid) {
            let disposeAlarmSendInfos = []
            if (!this.isBatch) {
              disposeAlarmSendInfos = [
                {
                  system: this.system,
                  alarmNo: this.alarmDetail.alarmNo,
                  alarmId:this.alarmDetail.id,
                  alarmIdStr:this.alarmDetail.idStr,
                  alarmSignTime: this.alarmDetail.alarmSignTime,
                  terminalPhoneNo: this.alarmDetail.terminalPhoneNo
                }
              ]
            } else {
              //批量处理
              this.alarmList.forEach((alarm) => {
                disposeAlarmSendInfos.push({
                  system: this.system,
                  alarmNo: alarm.alarmNo,
                  alarmId:alarm.id,
                  alarmIdStr:alarm.idStr,
                  alarmSignTime: alarm.alarmSignTime,
                  terminalPhoneNo: alarm.terminalPhoneNo
                })
              })
            }
            const data = {
              disposeAlarmSendInfos: disposeAlarmSendInfos,
              // disposeDescription: `调度员提醒您：${this.form.disposeDescription}`,
              disposeDescription:this.form.disposeDescription,
              disposeWay: this.form.disposeWay,
              phone: this.form.phone,
              alarmConfirm:this.form.alarmConfirm
            }
            handleDisposeAlarm(data).then((res) => {
              if (res.code === 1000) {
                this.$emit('cancelDialog', res.data)
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            return false
          }
        })
      }
    },
    // 获取报警详情
    getDetail () {
      if (this.isBatch) return //批量处理 不请求
      let alarmNos = []
      let data = {}
      if (this.isGlobalAlarm) {
        if (this.$store.state.vehicle.globalAlarmArr.length > 1) {
          this.$store.commit('cutGlobalAlarm')
        }
        data = {
          alarmNo: this.$store.state.vehicle.globalAlarmArr[0][0],
          system: this.system,
          terminalPhoneNo: this.alarmDetail.terminalPhoneNo,
          alarmSignTime: this.alarmDetail.alarmSignTime
        }
      } else {
        data = {
          alarmNo: this.alarmID[0],
          system: this.system,
          terminalPhoneNo: this.alarmDetail.terminalPhoneNo,
          alarmSignTime: this.alarmDetail.alarmSignTime,
          alarmEndTime: this.alarmDetail.alarmEndTime
        }
      }
      if (this.alarmID.length === 1) {
        queryAlarmDetail(data).then((res) => {
          if (res.code === 1000) {
            this.detailForm = res.data
            if (this.alarmID.length === 1) {
              this.form.terminalPhoneNo = this.detailForm.alarmBaseVO.alarmNo
              this.form.phone = this.detailForm.alarmBaseVO.phone
            } else {
              this.form.disposeWay = '3' //电话处理
            }
          }
        })
      }
    },
    
    // 获取文本模板
    getTemplateList () {
      queryMessageTemplateList().then((res) => {
        if (res.code === 1000) {
          // res.data.forEach((item) => {
          //   item.name = '调度员提醒您' + item.name
          // })
          res.data.unshift({ name: '自定义', id: '自定义' })
          this.templateList = res.data
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: res.msg
          })
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'BJDJ,CLLX,BJCLFS,BJQR' }).then((res) => {
        if (res.code === 1000) {
          this.disposeWayList = res.data.BJCLFS
          this.alarmConfirmList = res.data.BJQR
          this.alarmLevelObj = formatDict(res.data.BJDJ)
          this.disposeTypeObj = formatDict(res.data.CLLX)
          this.disposeWayObj = formatDict(res.data.BJCLFS)
        }
      })
    }
  },
  created () {
    this.system = sessionStorage.getItem('system').toString()
    this.getDicts()
    this.getTemplateList()
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$refs.alarmForm) {
        this.$refs.alarmForm.clearValidate()
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.alarm-dia {
  // height: calc(79vh - 149px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @include themify() {
    .fixed {
      flex: 1;
    }
    .scroll {
      overflow-y: auto;
    }
    .title {
      color: themed("n7");
      font-size: 14px;
      margin-bottom: 16px;
    }
    .header-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 6px;
      span {
        margin-right: 1vw;
      }
    }
    .information,
    .record,
    .deal {
      background-color: themed("n1");
    }
    .information {
      padding: 12px;
      margin-bottom: 16px;
      .head {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: themed("n8");
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
        // p:nth-of-type(4n) {
        //   text-align: right;
        // }
      }
      .split-line {
        border-bottom: 1px solid;
        border-bottom-color: themed("n4");
        margin: 0 -12px 12px;
      }
    }
    .record {
      padding: 12px;
      margin-bottom: 16px;
    }
    .deal {
      padding: 16px;
    }
    .sign {
      color: themed("b4");
    }
    .pointer {
      cursor: pointer;
    }
  }
}

.batch {
  height: calc(40vh - 149px);
}
/deep/.deal-form {
  .el-form-item {
    &:nth-of-type(1),
    &:nth-of-type(2) {
      width: 45%;
    }
    &:last-of-type {
      width: 100%;
      display: flex;
      .el-form-item__content {
        flex: 1;
      }
    }
  }
}
/deep/.el-carousel {
  width: 600px;
  img {
    width: 30vw;
  }
}
</style>
